import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Youtube = makeShortcode("Youtube");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p>{`最近自分はTwitterで「CSS設計」と検索してきて出てきたツイートをしている人をひたすらフォローしていて、たぶんこの記事を見てくれている人の中にも、Takazudoになんかしらんけどフォローされたと思っている人もいるかも知れない。`}</p>
      <p>{`それは、端的に言えば`}<a parentName="p" {...{
          "href": "https://amzn.to/3GriZeV",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`自分の書いた本が`}</a>{`そういう人に届いたら良いなと思ってそうしている。そういうのを見ていると単純に応援したい気分になってくるし、自分の書いた本がなんか役になってくれたらありがたいなと思っている。`}</p>
    </Intro>
    <Body mdxType="Body">
      <h2 {...{
        "id": "css設計ができると儲かるのか"
      }}>{`CSS設計ができると儲かるのか？`}</h2>
      <p>{`ただ、そういう「CSS設計」というキーワードで検索していると、CSS設計が出来ると稼げるだとか、CSSが書けると市場価値が高いとかいうツイートをしている人がいて、はーーん、そういうもんかねーーなどと考えたので、現時点での考えを書いておこうかと思った。`}</p>
      <p>{`とりあえず「CSS設計ができると儲かるのか？」に対する今の自分の回答は、「CSS設計ができるだけでは儲からない。それを使って儲けるという状態を作り出せるのは別の力」かと考えている。`}</p>
      <h2 {...{
        "id": "過去の自分の話"
      }}>{`過去の自分の話`}</h2>
      <p>{`自分の社会人になってからのWeb業界のキャリアでいうと、はじめは老舗と言われるような昔からある、それなりの大きさのWeb制作会社で、HTMLとCSSを書くところから始まった。自分はその仕事が好きだったので、当時のその会社の中では誰よりもCSSを書いていた。案件の規模も大きいものが大きかったので、いわゆるCSS設計の知識はその時に培われた部分が大きい。`}</p>
      <p>{`そして、もっと勉強しようとか一人でやってみようと思ってやめて、フリーランスになった。フリーランスが続いたのは1年間ぐらい。Web業界というのは人が辞めたり入ったりが結構多い業界であるように感じた。そして、Web制作会社としてはそれなりの大きさだったので、言ってみれば横のつながりはかなりあり、自分がフリーランスになったときに、HTMLとCSSのコーディングを依頼してくれる人は沢山いた。`}</p>
      <p>{`ふむふむ、えーうらやましい。それだったらさぞ儲かったのでは？と、CSS勉強中という感じの人には思われるかもしれないが、この時の自分は全く儲かっていなかった。理由は単純で、自分が日々暮らしていけるだけの金額しか請求しなかったため。`}</p>
      <p>{`だったらどうしたらよかった？ 2倍、3倍の金額で見積もりをしていたら良かった？ しかしそれは高いのではないか？ そんな高くて良いのか？ という問いに、おそらくCSSを学んでいるだけでは答えられないと思う。少なくとも当時の自分は全くそんなことは考えていなかった。ただ言われるままの金額で引き受けて、日々仕事をこなすだけであった。`}</p>
      <p>{`そういう自分なので、いわゆる駆け出しエンジニア的な人がCSSをかなり勉強したぞ！BEMなりTailwind CSSなりを使えるようになったぞ！などとなっても、自分と同じ状態に陥ることは十分に考えられる道筋かと思う。`}</p>
      <h2 {...{
        "id": "css設計ができるとどういう価値があるのか"
      }}>{`CSS設計ができるとどういう価値があるのか`}</h2>
      <p>{`この問題を突破するためには、自分にとっては「仕事の価値」を認識することがブレイクスルーだった。話がそれるけれども、そのきっかけはBlair Ennsという人の本を読んだことがきっかけで、以下の動画は大変オススメである。20回ぐらいは聞いているし、定期的に聞き直している。`}</p>
      <Youtube url="https://youtu.be/J6Su5Vx3x5U" mdxType="Youtube" />
      <p>{`CSS設計ができることの価値というのはどういうことなのであろうか。この問いに答えることが出来るものだけがCSS設計で他人よりも稼ぐことが出来る。もしくは誰かがあなたを見つけてくれるかどちらか。`}</p>
      <p>{`例えばランディングページを作るとする。2,3ページで新商品をプロモーションするためのもの。このページのCSSを書くのに何百万もお金を出す人がいるのか？と言われると、自分はあまりそういうのは想像できない。一通りの対象環境で表示されれば満足であるし、そこが満たされればそれ以上コストをかけたくないのがごく普通ではなかろうか。`}</p>
      <p>{`個人商店みたいなWebサイトだったらどうだろう。例えば駅前の本屋とか。そういうサイトでも同じかと思う。ちゃんと表示される以上の何を望む？ 別に何も望まれちゃいないだろう。たとえあなたがCSS設計スーパーマンであって、いくらCSS設計に優れていることをアピールしたって、駅前の本屋にそれが伝わることは一生ない。`}</p>
      <p>{`でもCSS設計が出来る人を求めているところはある。ちゃんと表示される以上の様々なことを。それは例えば、継続的に開発されるWebサービスを開発している会社。そういうところでCSS設計がテキトーだと、端的に言ってサービスの開発速度が遅くなる。一度作ったものをもう一度作り直したりする必要が出たりもする。`}</p>
      <p>{`これを解決できるということはとても価値があることであるのを、Webサービスを作っている側は理解している可能性がある。このサービスのリリースが遅れたら、競合のホゲホゲ社が同じサービスを先にリリースする可能性がある。CSSが破綻して単純に作り直したら、単純に作り直す分のあなたの給料を会社は払わねばならない。ここで生まれる価値は、駅前の本屋のWebサイトを作るのとはワケが違う。だからそういうCSS設計ができる人を、本気でWebサービスをやりたい会社は求めている。それができると、何百万何千万の利益の差が生まれてしまうかもしれないから。ここで生まれる価値というのは、利益を生み出すと言うよりかは、損失を減らすという価値である側面が強い。故に、そういう環境においては、CSS設計ができるという人は高い給料で雇われる、高い額で依頼されることが可能になる。`}</p>
      <p>{`つまり、1枚のCSSを書くことの価値は、端的にいうとそれが利用されるケースで全く違う。これはCSSに限った話では無いが、ごく単純に言うと、クライアントの大きさで仕事の価値が変わる。だから、CSS設計が出来るだけで儲かるというわけではない。その儲からなかったフリーランスのCSS書きが10年以上前の自分である。`}</p>
      <h2 {...{
        "id": "css設計の価値を売るのは難しい"
      }}>{`CSS設計の価値を売るのは難しい`}</h2>
      <p>{`そんなわけで、CSS設計ができるだけで儲かるのは難しいかと思う。自分が想像するに、CSSが書けて一番儲かる道として易しいのは、今書いたような、CSS設計が必要とされる現場で社員として雇われることかと思う。`}</p>
      <p>{`これがフリーランスだったらどうだろう。世の中にあるフリーランスに仕事を依頼しまっせみたいなWebサービス経由だったら、そもそもそういう、CSS設計を本気でやっている人を見つけ出すことがまず難しい。だいたい、CSS設計ができることっていうのは外から非常に評価しづらい。ソースコードを見せられたところで、それを受け取る側も判断が難しいし、見せる側も、自分のCSS設計の何が良いのかをプレゼンできたら、それはその時点で、開発以外のスキルも持ち合わせた相当デキるヤツなんじゃないだろうか。`}</p>
      <p>{`そんなわけで、高いCSS設計を持っている人を、多くの人はそういうようなWebサービス経由では探さない。どうするかと言えば、人づてで探してくるか、高めの給与で求人サイトで募集だなどとなっているに違いない。`}</p>
      <p>{`そういうフリーランスに仕事を依頼したいんですみたいなWebサイトにおいては、そもそも丸っとWebサイトを作ってくれる人の方がマッチするんじゃないだろうか。先程挙げた例であれば、ランディングページだったり、個人商店みたいなWebサイトを想像して見るに、そういうケースの仕事においては、HTMLとCSSを書くことに対して大きな価値を見いださない。その環境においては、CSS設計ができる人と、CSSはまぁとりあえず書けるんですという人の報酬の差は殆どない。それはそこで生まれる価値に差がないため。そういう環境においては、たぶんWordPressもできまっせとかいう方が重宝されるんではないかと思う。`}</p>
      <h2 {...{
        "id": "css設計ができると市場価値が高いのか"
      }}>{`CSS設計ができると市場価値が高いのか`}</h2>
      <p>{`で、はじめの`}</p>
      <ul>
        <li parentName="ul">{`CSS設計ができると市場価値が高い`}</li>
        <li parentName="ul">{`CSS設計ができると儲かる`}</li>
      </ul>
      <p>{`というのところに立ち戻ると、うん、そうね、市場価値が高いのはそうだとおもう。儲かるというのも間違ってはいないかと思う。ただ、CSS設計ができるとガッポガッポ儲かりまっせみたいなそういう言い方はいかがなものか（笑）と思ってしまう。`}</p>
      <p>{`なので、Web開発のスキルを身につけるぞ、そのためにCSS設計を勉強するぞ〜みたいに考えている人は、上記のようなことを理解しているととても良いんではないかなーーと思う。`}</p>
      <p>{`人が誰かに何かを頼むときっていうのは、何かに困っているからであるというのを最近自分は考えるようになった。たとえばあなたにCSSを書いてと依頼する人は、何か問題を抱えていて、それを解決する手伝いをするのがあなたが仕事でできることだったりする。仕事っていうのは基本的にそういうもんで、そこを見つめ続けている限りは大きく間違うことはあんまりない。でも、そこでCSS設計の力が刺さるのっていうのはなかなか狙いを定めないとならんよねーというのは思う。`}</p>
      <p>{`いや、頼む方もCSS設計ができると何が嬉しいのかを分かっている人は稀だったりするのだ。そういう人に対しては、プロジェクトをまるっと引き受けてうまくいかせる中で、知らずとCSS設計のデキの良さに恩恵を受ける。かもしれない。`}</p>
      <p>{`……とか書いてたら、そもそもCSS設計が儲かりまっせなんて言葉を真に受けてる人は存在するのか？という気もしてきた。でもまぁここに書いたことはそんなにハズれてはいないとは思いますよ。`}</p>
    </Body>
    <Outro mdxType="Outro">
      <p>{`そういうCSS設計の話でこれ知っといて！ってのをまとめたのがこの本なので是非気になる方は読んで頂ければと🙏 （結局宣伝か）`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/3J13rQL",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`ざっくりつかむ　CSS設計`}</a></li>
      </ul>
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      